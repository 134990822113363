import React, { useState } from 'react'
import axios from 'axios'
import { PhantomKeyLogo } from '../../components/Icons/PhantomKeyLogo'
import {
  PopupHeader,
  PopupSubHeader,
  AuthenticationAppButton,
  SeparatorLine,
  ElectronicIdButton,
  QRCodeWrapper,
  ReturnButton,
  FormContent,
  Label,
  CodeWrapper,
  CodeHeader,
} from './PhantomKeyPopup.styles'
import { QRCodeSVG } from 'qrcode.react'
import { TextInput } from '../../components/TextInput/TextInput'
import { Loading } from '../../components/Loading/Loading'
import {
  Form,
  FormTitle,
  Input,
  InputLabel,
  InputWrapper,
} from '../../pages/LoginPage/LoginPage.styles'

export const PhantomKeyPopup = () => {
  const [emailAddress, setEmail] = useState('')
  const [phoneNumber, setPhone] = useState('')

  const [validationErrorEmail, setValidationErrorEmail] = useState(false)
  const [validationErrorPhone, setValidationErrorPhone] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const [popupState, setPopupState] = useState<'buttons' | 'qrcode' | 'form'>(
    'form'
  )

  const [phantomKeyLoading, setPhantomKeyLoading] = useState(false)
  const [phantomKeyCode, setPhantomKeyCode] = useState('')

  const phantomKeyProceed = async () => {
    setPhantomKeyLoading(true)

    try {
      const { data } = await axios.post<{
        shortSessionId: string
      }>(
        'https://api.phantomkey.io/reuse/create-session',
        {
          applicationName: 'Apex onboarding',
          relyingPartyName: 'ApexBank',
          phoneNumber,
        },
        {
          withCredentials: true,
        }
      )

      setPhantomKeyCode(data.shortSessionId)

      setPopupState('qrcode')
    } catch (error) {}

    setPhantomKeyLoading(false)
  }

  const renderPopupContent = () => {
    switch (popupState) {
      case 'qrcode':
        return (
          <>
            {/* <ReturnButton
              onClick={() => {
                setPopupState('buttons')
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="18"
                viewBox="0 0 22 18"
                fill="none"
              >
                <path
                  d="M10 16L3 9M3 9L10 2M3 9L20.5 9"
                  stroke="#9E9FA5"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
              </svg>
            </ReturnButton> */}
            {/* <PopupHeader>Download an authentication app</PopupHeader> */}

            <CodeHeader> PhantomKey code </CodeHeader>

            <CodeWrapper>{phantomKeyCode}</CodeWrapper>
            {/* <PopupSubHeader>
              Scan the QR code by your mobile phone and download the
              authentication app form the app market
            </PopupSubHeader>

            <QRCodeWrapper>
              <QRCodeSVG
                value={'https://url-to-download-app-WIP.com/download'}
                bgColor="#eff1f5"
                size={220}
              />
            </QRCodeWrapper> */}
          </>
        )

      case 'form':
        return (
          <>
            {/* <ReturnButton
              onClick={() => {
                setPopupState('buttons')
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="18"
                viewBox="0 0 22 18"
                fill="none"
              >
                <path
                  d="M10 16L3 9M3 9L10 2M3 9L20.5 9"
                  stroke="#9E9FA5"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
              </svg>
            </ReturnButton> */}

            <PopupHeader>Sign up</PopupHeader>
            <FormContent>
              <InputWrapper>
                <Label htmlFor="email">Email address</Label>
                <input
                  type="email"
                  name="email"
                  placeholder="email@domain.com"
                  required={true}
                  value={emailAddress}
                  disabled={isLoading}
                  onChange={(e) => {
                    setEmail(e.target.value)
                  }}
                />
              </InputWrapper>
              <InputWrapper>
                <Label htmlFor="email">Phone</Label>
                <input
                  id="phone-input"
                  name="phone"
                  type="text"
                  placeholder="+xxxxxxxxxxx"
                  required={true}
                  value={phoneNumber}
                  disabled={isLoading}
                  onChange={(e) => {
                    setPhone(e.target.value)
                  }}
                />
              </InputWrapper>

              <ElectronicIdButton
                type="submit"
                disabled={
                  isLoading ||
                  validationErrorEmail ||
                  validationErrorPhone ||
                  !emailAddress ||
                  !phoneNumber
                }
                onClick={async () => {
                  if (validationErrorEmail || validationErrorPhone) return

                  setIsLoading(true)
                  console.log(emailAddress, phoneNumber)

                  try {
                    const { data } = await axios.post<{
                      userExists: boolean
                    }>(
                      'https://api.phantomkey.io/user/does-exist',
                      {
                        // redirectUrl: 'https://another.sample-bank.com/',
                        emailAddress,
                        phoneNumber,
                        // applicationName: 'Sample bank onboarding',
                      },
                      {
                        headers: {
                          'Content-Type': 'application/json',
                        },
                      }
                    )

                    if (data.userExists) {
                      const { data: createPhantomData } = await axios.post<{
                        shortSessionId: string
                      }>(
                        'https://api.phantomkey.io/reuse/create-session',
                        {
                          applicationName: 'Apex onboarding',
                          relyingPartyName: 'ApexBank',
                          phoneNumber,
                        },
                        {
                          withCredentials: true,
                        }
                      )

                      window.location.href =
                        window.location.href = `https://link.phantomkey.io/use/${
                          createPhantomData.shortSessionId
                        }?redirectUrl=https://demo.sample-bank.com&email=${emailAddress}&phone=${phoneNumber.replace(
                          '+',
                          ''
                        )}`
                    } else {
                      const { data: createTransactionData } = await axios.post<{
                        transactionId: string
                        proceedUrl: string
                      }>(
                        'https://api.phantomkey.io/create-transaction',
                        {
                          redirectUrl: 'https://another.sample-bank.com/',
                          emailAddress,
                          phoneNumber,
                          applicationName: 'Apex onboarding',
                        },
                        {
                          headers: {
                            'Content-Type': 'application/json',
                          },
                        }
                      )

                      window.location.href = createTransactionData.proceedUrl
                    }
                  } catch (error) {
                    console.error(error)
                    setIsLoading(false)
                  }
                }}
              >
                {isLoading ? (
                  <Loading
                    bgColor="#aaacaa"
                    primary="#eee"
                    width="25px"
                    padding="0 0"
                    thickness={3}
                  />
                ) : (
                  'Start'
                )}
              </ElectronicIdButton>
            </FormContent>
          </>
        )

      default:
        return (
          <>
            <PopupHeader>Verify your identity</PopupHeader>

            <PopupSubHeader>
              Use Phantom Key application to confirm your identity.
            </PopupSubHeader>

            <AuthenticationAppButton
              onClick={phantomKeyProceed}
              disabled={phantomKeyLoading}
            >
              {phantomKeyLoading ? (
                <Loading
                  bgColor="#1fbfb7"
                  primary="#eeeeee"
                  width="25px"
                  padding="0 0"
                  thickness={3}
                />
              ) : (
                <PhantomKeyLogo color1="#fff" color2="#fff" />
              )}
            </AuthenticationAppButton>

            <SeparatorLine />

            <PopupSubHeader>
              Video identification process when you will be asked to show your
              ID.
            </PopupSubHeader>

            <ElectronicIdButton
              onClick={() => {
                setPopupState('form')
              }}
              disabled={phantomKeyLoading}
            >
              Video Identification
            </ElectronicIdButton>
          </>
        )
    }
  }

  return <>{renderPopupContent()}</>
}
