import styled from 'styled-components'
import { tablet } from '../../constants/breakpoints'

export const Page = styled.div`
  width: 100%;
  //max-width: 1440px;
  margin: 72px auto 0 auto;

  /* @media (min-width: calc(${tablet} + 1px)) {
    min-height: calc(100vh - 72px);
  } */

  @media (min-width: calc(${tablet} + 1px)) {
    background-color: #fff;
  }
`

export const HeadingBannerMobile = styled.div`
  width: 100%;
  padding: 30px 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  @media (min-width: calc(${tablet} + 1px)) {
    display: none;
  }

  & > h1 {
    color: #000;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px; /* 125% */
    margin: 0;

    /* @media (min-width: calc(${tablet} + 1px)) {
      margin-bottom: 10px;
    } */

    & > span {
      background: linear-gradient(91deg, #41c7ff 59.98%, #8b4cff 99.3%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: Poppins;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 40px;
    }
  }

  & > div {
    color: #000;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 25.5px; /* 170% */

    /* @media (min-width: calc(${tablet} + 1px)) {
      margin-bottom: 40px;
    } */
  }

  & > button {
    cursor: pointer;
    height: 55px;
    width: 100%;
    max-width: 350px;
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px; /* 333.333% */
    letter-spacing: 0.78px;
    border-radius: 10px;
    background: linear-gradient(92deg, #30e2ff -18.88%, #9d2bff 120.58%);
    border: none;
  }
`

export const ImageBannerMobile = styled.div`
  background-color: #fff;
  width: 100%;
  max-width: 100vw;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  padding: 20px 0;
  gap: 20px;

  @media (min-width: calc(${tablet} + 1px)) {
    /* height: calc(100% - 420px - 72px);
    gap: 40px;
    padding: 40px 0; */

    display: none;
  }

  h3 {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 25.5px; /* 170% */
    margin: 0;

    /* @media (min-width: calc(${tablet} + 1px)) {
      font-size: 20px;
    } */
  }

  & > img {
    width: 100%;
    height: 328px;
    object-fit: cover;

    /* @media (min-width: calc(${tablet} + 1px)) {
      height: 600px;
    } */
  }
`

export const HeadingBannerDesktop = styled.div<{ url: string }>`
  @media (max-width: calc(${tablet})) {
    display: none;
  }

  width: 100%;
  height: 585px;
  padding: 66px 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-image: linear-gradient(
      to right,
      #eff1f5 calc(100% - 874px),
      rgba(239, 241, 245, 0.5) calc(60%),
      rgba(0, 0, 0, 0) 85%
    ),
    url(${(props) => props.url});
  background-position: center, right;
  background-size: cover, contain;
  background-repeat: no-repeat;
  gap: 40px;

  @media (min-width: 1920px) {
    background-image: linear-gradient(
        to right,
        #eff1f5 calc(100% - 874px),
        rgba(239, 241, 245, 0.5) calc(70%),
        rgba(0, 0, 0, 0) 85%
      ),
      url(${(props) => props.url});
  }

  & > h1 {
    color: #000;
    font-family: Poppins;
    font-size: 60px;
    font-style: normal;
    font-weight: 600;
    line-height: 80px;
    margin: 0;

    & > span {
      background: linear-gradient(92deg, #42c6ff 34.95%, #894eff 66.44%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: Poppins;
      font-size: 60px;
      font-style: normal;
      font-weight: 600;
      line-height: 80px;
    }
  }

  & > div {
    color: #000;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  & > button {
    cursor: pointer;
    height: 55px;
    width: 100%;
    max-width: 350px;
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 1.04px;
    border-radius: 10px;
    background: linear-gradient(92deg, #30e2ff -18.88%, #9d2bff 120.58%);
    border: none;
    transition: background 0.5s ease-out;

    &:hover {
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
    }
  }
`

export const DesktopBodyWrapper = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: calc(${tablet})) {
    display: none;
  }
`

export const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  margin: 50px 0;
  overflow-x: hidden;

  h2 {
    margin: 0;
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 25.5px;
  }

  @media (max-width: calc(${tablet})) {
    display: none;
  }
`

export const CardsWrapper = styled.div`
  width: calc(100% + 140px);
  display: flex;
  justify-content: space-between;

  gap: 20px;

  padding: 0 140px;
`

export const Card = styled.div`
  background-color: #eff1f5;
  border-radius: 16px;
  max-width: 382px;
  width: 100%;
  aspect-ratio: 1 / 1;

  h3 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    height: 62px;
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 25.5px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0 0 16px 16px;
  }
`
