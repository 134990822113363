import styled from 'styled-components'
import { mobile } from '../../constants/breakpoints'

export const PopupHeader = styled.h1`
  color: #000;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px; /* 125% */
  margin: 0;
  margin-bottom: 20px;

  @media (min-width: calc(${mobile} + 1px)) {
    width: 100%;
    text-align: center;
  }
`

export const PopupSubHeader = styled.div`
  color: #000;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.5px; /* 170% */
  width: 100%;
`

export const AuthenticationAppButton = styled.button`
  cursor: pointer;
  border-radius: 10px;
  /* background: linear-gradient(92deg, #30e2ff -18.88%, #9d2bff 120.58%); */
  /* background: #fcfdfd; */
  background-color: #1fbfb7;
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0.78px;

  margin-top: 30px;
  border: none;
  max-width: 350px;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));

  &:hover {
    transition: box-shadow 0.2s ease-out;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
  }
`

export const SeparatorLine = styled.div`
  background-color: #d8ddec;
  height: 1px;
  width: 100%;
  margin: 40px 0;
`

export const ElectronicIdButton = styled.button<{ disabled?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  border-radius: 10px;
  background: #fff;
  border: 2px solid #1fbfb7;
  width: 100%;
  height: 55px;

  color: #1fbfb7;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0.78px;

  margin-top: 30px;
  /* border: none; */

  max-width: 350px;

  &:hover {
    box-shadow: ${({ disabled }) =>
      disabled ? 'none' : '0px 4px 8px rgba(0, 0, 0, 0.2);'};
  }
`

export const QRCodeWrapper = styled.div`
  margin-top: 30px;
`

export const CodeHeader = styled.div`
  color: #000;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px; /* 125% */
  margin: 0;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
`

export const CodeWrapper = styled.div`
  color: #000;
  font-family: Roboto;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px; /* 125% */
  letter-spacing: 10px;
  margin: 0;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ReturnButton = styled.button`
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  position: absolute;
  top: 30px;
  left: 30px;
`

export const FormContent = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  input[type='text'],
  input[type='email'] {
    background-color: white;
    padding: 14px;
    border-radius: 8px;
    border: 1px solid silver;
    box-shadow: none;
    margin: 0;
    color: gray;
    outline: none;
    font-size: 14px;
    font-weight: 400;
    width: 100%;
  }
`

export const Label = styled.label`
  color: #000;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.5px; /* 170% */
  width: 100%;
`
