/* eslint-disable @typescript-eslint/naming-convention */
import 'mobx-react-lite'
import React, { createContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { createGlobalStyle } from 'styled-components'
import { useElementSize } from 'usehooks-ts'

import Favicon32 from '../assets/favicons/32x32.png'
import Favicon16 from '../assets/favicons/16x16.png'

import { amexTheme } from '../constants/amex.theme'
import { bmwTheme } from '../constants/bmw.theme'
import { ikanoTheme } from '../constants/ikano.theme'
import { miniTheme } from '../constants/mini.theme'
import { signicatTheme } from '../constants/signicat.theme'
import { getQueryVariable } from '../methods/getQueryVariable'
import { rootStore } from '../store/Root.store'
import FontStyles from '../styles/fonts.styles'
import { ContentRouter } from './ContentRouter'
import { Toasts } from './Toast/Toast'

import FuturaUrl from '../assets/fonts/FuturaLT.ttf'

export const StoreContext = createContext(rootStore)

interface GlobalStyleProps {
  bgColor?: string
  fontFamily?: string
  checkboxColor?: string
  checkboxColorActive?: string
  container?: {
    bgColor?: string
  }
  linkColor?: string
  color?: string
}

const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  @font-face {
    font-family: 'Futura LT';
    src:  url(${FuturaUrl}) format('truetype'),
  }

  body {
    font-family: 'Inter';
    background-color: ${(props) => (props.bgColor ? props.bgColor : 'gray')};
    padding: 0;
    margin: 0;
    font-family: ${(props) => (props.fontFamily ? props.fontFamily : '')};

    ::-webkit-scrollbar {
      width: 11px;
      height: 10px;
    }

    ::-webkit-scrollbar-track {
      background-color: #FFF;
      border-radius: 0;
    }

    ::-webkit-scrollbar-thumb {
      background: #0071D7;
      border-radius: 0;
    }

    button {
      justify-content: center;
    }

    /* this is only checkbox styles, move it */
    input[type="checkbox"] {
      padding: 0;
      height: initial;
      width: initial;
      margin-bottom: 0;
      display: none;
      cursor: pointer;
    }

    .label-text {
      position: relative;
      display: flex;
    }

    .label-text:before {
      width: 20px;
      height: 20px;
      content:'';
      -webkit-appearance: none;
      background-color: transparent;
      border: ${(props) =>
        props.checkboxColor
          ? `${props.checkboxColor} 2px solid !important`
          : 'gray 2px solid !important'};
      padding: 8px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 10px;
      top: 0px;
      box-sizing: border-box;
    }

    input:checked + .label-text:before {
      background-color: ${(props) =>
        props.checkboxColorActive ? props.checkboxColorActive : 'black'};
      border: ${(props) =>
        props.checkboxColorActive
          ? `${props.checkboxColorActive} 2px solid !important`
          : 'gray 2px solid !important'};
    }

    input:checked + .label-text:after {
      content: '';
      display: block;
      position: absolute;
      top: 2px !important;
      left: 6.5px;
      width: 6px;
      height: 9.5px;
      border: solid
        ${(props) =>
          props.container.bgColor ? `${props.container.bgColor}` : 'white'};
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
`

function changeTheme(themeId: string) {
  if (themeId === 'signicatTheme') {
    rootStore.AppState.setTheme(signicatTheme)
  }

  if (themeId === 'bmwTheme') {
    rootStore.AppState.setTheme(bmwTheme)
  }

  if (themeId === 'miniTheme') {
    rootStore.AppState.setTheme(miniTheme)
  }

  if (themeId === 'amexTheme') {
    rootStore.AppState.setTheme(amexTheme)
  }

  if (themeId === 'ikanoTheme') {
    rootStore.AppState.setTheme(ikanoTheme)
  }
}

function initOnboarding(merchantId: string) {
  rootStore.OnboardingState.setMerchantId(merchantId)
}

export const App: React.FC = () => {
  const [paramEnv, setParamEnv] = useState('')
  const [appRef, { width, height }] = useElementSize()
  const merchantId = getQueryVariable('merchantId')
  if (merchantId) initOnboarding(merchantId)
  const themeId = getQueryVariable('themeId')
  if (themeId) changeTheme(themeId)
  const languageFromQuery = getQueryVariable('language')

  useEffect(() => {
    rootStore.AppState.setPageWidth(width)
  }, [width])

  useEffect(() => {
    rootStore.AppState.setPageHeight(height)
  }, [height])

  return (
    <StoreContext.Provider value={rootStore}>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/assets/apple-touch-icon.png"
        />
        <meta name="description" content="Onboarding Web" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700;800&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
          rel="stylesheet"
        />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
          rel="stylesheet"
        />

        <title>Signicat Merchants Dashboard</title>
      </Helmet>
      <FontStyles />
      <GlobalStyle
        bgColor={'#eff1f5'}
        fontFamily={rootStore.AppState.theme.globals.fontFamily}
        linkColor={rootStore.AppState.theme.globals.linkColor}
        checkboxColor={rootStore.AppState.theme.globals.checkboxColor}
        checkboxColorActive={
          rootStore.AppState.theme.globals.checkboxColorActive
        }
        color={rootStore.AppState.theme.container.color}
        container={rootStore.AppState.theme.container}
      />
      <div className="App" ref={appRef}>
        <Toasts />
        <ContentRouter />
      </div>
    </StoreContext.Provider>
  )
}
