import { observer } from 'mobx-react'
import React from 'react'
import styled from 'styled-components'

export const InnerAppLayout = styled.div`
  padding: 36px 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 46px;
`
export const InnerAppContent = styled.div`
  border-radius: 12px;
  border: 1px solid #dcdcdd;
  background: #fff;

  width: 100%;
  box-sizing: border-box;
  padding: 50px 82px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 800px;

  @media (max-width: 980px) {
    /* padding: 10px 20px 20px; */
  }
`

export const PhantomKeyLogoWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;

  span {
    display: block;
    width: 2px;
    height: 54px;
    background-color: #000;
  }
`

export const BrowserViewContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* gap: 20px; */
`

export const CompleteIconWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
`

export const CompleteTitle = styled.h1`
  color: #000;
  text-align: center;
  font-family: 'Futura LT';
  font-size: 36px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  margin: 66px 0 70px;

  span {
    /* color: #2cb5ae; */
    font-family: 'Futura LT';
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    background: linear-gradient(91deg, #41c7ff 59.98%, #8b4cff 99.3%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`

const TextLogo = styled.div`
  color: #000;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

export const CompletePage = observer(() => {
  const urlParams = new URLSearchParams(window.location.search)
  const firstName = urlParams.get('firstName')
  const lastName = urlParams.get('lastName')

  return (
    <InnerAppLayout>
      <PhantomKeyLogoWrapper>
        <TextLogo>SampleBank</TextLogo>
        <span />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="124"
          height="54"
          viewBox="0 0 124 54"
          fill="none"
        >
          <path
            d="M30.9749 14.3833V22.6177H28.5264V2.97168H31.3405C34.2422 2.97168 36.2876 3.44487 37.4769 4.39124C38.6661 5.33762 39.262 6.75969 39.262 8.65244C39.262 10.5452 38.6235 12.0399 37.3467 12.9787C36.0698 13.9176 34.4149 14.3858 32.382 14.3858H30.9749V14.3833ZM30.9749 5.15986V12.1951H31.7561C32.4671 12.1951 33.098 12.1525 33.6438 12.0649C34.1921 11.9773 34.7329 11.817 35.2712 11.5842C35.8095 11.3489 36.2276 10.9808 36.523 10.4776C36.8184 9.97436 36.9661 9.34845 36.9661 8.60236C36.9661 7.85628 36.8109 7.26542 36.4979 6.77721C36.185 6.2915 35.7419 5.93849 35.1685 5.72067C34.5952 5.50286 34.0394 5.35514 33.5011 5.27753C32.9628 5.19992 32.3369 5.15986 31.6259 5.15986H30.9749Z"
            fill="#110F19"
          />
          <path
            d="M41.813 0H44.1589V12.065H44.2115C44.5595 11.4216 45.0677 10.9284 45.7362 10.5804C46.4047 10.2324 47.1207 10.0596 47.8868 10.0596C49.4841 10.0596 50.6058 10.5278 51.2492 11.4667C51.8926 12.4055 52.2131 13.6999 52.2131 15.3498V22.6204H49.8672V15.6377C49.8672 14.4385 49.6969 13.5572 49.359 12.9939C49.021 12.4306 48.2949 12.1477 47.1833 12.1477C46.6976 12.1477 46.272 12.2077 45.9064 12.3304C45.5409 12.4531 45.2555 12.6534 45.0477 12.9288C44.8399 13.2067 44.6696 13.4721 44.5395 13.7224C44.4093 13.9753 44.3191 14.3308 44.2666 14.7915C44.214 15.2522 44.1839 15.6127 44.1764 15.8731C44.1664 16.1334 44.1639 16.5165 44.1639 17.0197V22.6229H41.818V0H41.813Z"
            fill="#110F19"
          />
          <path
            d="M67.5328 10.4222V22.6174H65.1869V20.7146H65.1343C64.0928 22.2619 62.7108 23.033 60.9908 23.033C59.2708 23.033 57.7812 22.3946 56.7296 21.1177C55.6781 19.8409 55.1523 18.2811 55.1523 16.4409C55.1523 14.7034 55.6831 13.2062 56.7422 11.9469C57.8012 10.6876 59.1907 10.0566 60.9107 10.0566C62.6307 10.0566 64.0728 10.8553 65.1318 12.4551H65.1844V10.4222H67.5303H67.5328ZM61.3564 20.95C62.5907 20.95 63.5571 20.5244 64.2606 19.6731C64.9641 18.8219 65.3146 17.7704 65.3146 16.521C65.3146 15.2717 64.9591 14.2502 64.2456 13.4065C63.532 12.5653 62.5681 12.1422 61.3539 12.1422C60.1396 12.1422 59.2558 12.5728 58.5523 13.4315C57.8488 14.2903 57.4983 15.3218 57.4983 16.5185C57.4983 17.7153 57.8463 18.7768 58.5398 19.6456C59.2333 20.5143 60.1721 20.9475 61.3539 20.9475L61.3564 20.95Z"
            fill="#110F19"
          />
          <path
            d="M73.5772 10.4221V12.0645H73.6298C73.9778 11.4211 74.486 10.9278 75.1545 10.5798C75.823 10.2318 76.539 10.0591 77.3051 10.0591C78.9025 10.0591 80.0241 10.5273 80.6675 11.4661C81.311 12.405 81.6314 13.6994 81.6314 15.3493V22.6198H79.2855V15.6372C79.2855 14.4379 79.1153 13.5567 78.7773 12.9933C78.4393 12.43 77.7132 12.1471 76.6016 12.1471C76.1159 12.1471 75.6903 12.2072 75.3248 12.3299C74.9592 12.4526 74.6738 12.6529 74.466 12.9283C74.2582 13.2062 74.088 13.4715 73.9578 13.7219C73.8276 13.9748 73.7375 14.3303 73.6849 14.791C73.6323 15.2516 73.6023 15.6122 73.5948 15.8725C73.5847 16.1329 73.5822 16.516 73.5822 17.0192V22.6223H71.2363V10.4271H73.5822L73.5772 10.4221Z"
            fill="#110F19"
          />
          <path
            d="M88.195 12.6108V22.6178H85.8491V12.6108H84.5723V10.4226H85.8491V5.7583H88.195V10.4226H90.4884V12.6108H88.195Z"
            fill="#110F19"
          />
          <path
            d="M93.2106 11.9343C94.4699 10.6825 96.0121 10.0591 97.8348 10.0591C99.6574 10.0591 101.2 10.685 102.459 11.9343C103.718 13.1861 104.349 14.7234 104.349 16.546C104.349 18.3687 103.718 19.9084 102.459 21.1577C101.2 22.4095 99.6574 23.0329 97.8348 23.0329C96.0121 23.0329 94.4699 22.407 93.2106 21.1577C91.9512 19.9059 91.3203 18.3687 91.3203 16.546C91.3203 14.7234 91.9487 13.1836 93.2106 11.9343ZM100.779 13.5492C99.9629 12.7154 98.9814 12.2973 97.8348 12.2973C96.6881 12.2973 95.7067 12.7154 94.8905 13.5492C94.0743 14.3829 93.6662 15.3818 93.6662 16.546C93.6662 17.7102 94.0743 18.6866 94.8905 19.5303C95.7067 20.3741 96.6881 20.7947 97.8348 20.7947C98.9814 20.7947 99.9629 20.3741 100.779 19.5303C101.595 18.6891 102.003 17.6927 102.003 16.546C102.003 15.3993 101.595 14.3829 100.779 13.5492Z"
            fill="#110F19"
          />
          <path
            d="M109.406 10.4221V11.9593H109.459C109.807 11.4035 110.27 10.9479 110.853 10.5924C111.434 10.2368 112.048 10.0591 112.691 10.0591C114.203 10.0591 115.375 10.7801 116.209 12.2222C116.592 11.5613 117.12 11.0355 117.799 10.6449C118.477 10.2544 119.196 10.0591 119.962 10.0591C122.653 10.0591 124 11.8141 124 15.3217V22.6173H121.654V15.7123C121.654 15.2441 121.632 14.831 121.589 14.4755C121.546 14.12 121.449 13.7469 121.304 13.3564C121.156 12.9658 120.921 12.6654 120.6 12.4576C120.28 12.2498 119.874 12.1446 119.388 12.1446C118.903 12.1446 118.49 12.2448 118.152 12.445C117.814 12.6453 117.553 12.8882 117.37 13.1736C117.188 13.459 117.045 13.8195 116.94 14.2552C116.835 14.6883 116.77 15.0814 116.744 15.4269C116.719 15.7749 116.704 16.173 116.704 16.6261V22.6198H114.359V16.1579C114.359 15.7398 114.349 15.3943 114.333 15.1164C114.316 14.8385 114.268 14.4905 114.191 14.0749C114.113 13.6568 114.001 13.3288 113.853 13.0835C113.705 12.8406 113.482 12.6228 113.189 12.4325C112.894 12.2423 112.538 12.1471 112.12 12.1471C111.529 12.1471 111.039 12.2823 110.648 12.5502C110.258 12.8206 109.98 13.2011 109.814 13.6969C109.649 14.1926 109.542 14.6558 109.489 15.0914C109.436 15.527 109.411 16.0378 109.411 16.6286V22.6223H107.065V10.4271H109.411L109.406 10.4221Z"
            fill="#110F19"
          />
          <path
            d="M32.4398 34.8232L38.5111 26.5361H43.228L35.7746 35.6043L43.6436 46.1822H38.7189L32.4398 37.3493H32.3872V46.1822H28.5566V26.5361H32.3872V34.8232H32.4398Z"
            fill="#1FBFB7"
          />
          <path
            d="M56.6195 40.3738H48.2023C48.2023 41.3127 48.4051 42.1189 48.8157 42.7974C49.2238 43.4758 49.8622 43.8138 50.731 43.8138C51.79 43.8138 52.6062 43.233 53.1795 42.0688L56.5419 42.6171C56.1063 43.8864 55.3702 44.8679 54.3262 45.5614C53.2822 46.2549 52.0854 46.6029 50.731 46.6029C48.7856 46.6029 47.2359 45.982 46.0792 44.7402C44.9225 43.4984 44.3467 41.886 44.3467 39.9057C44.3467 37.9253 44.88 36.2103 45.949 34.9159C47.0181 33.6215 48.5503 32.9756 50.5482 32.9756C52.5461 32.9756 54.0558 33.6215 55.0823 34.9159C56.1063 36.2103 56.6195 37.8827 56.6195 39.9332V40.3763V40.3738ZM48.3074 38.108H53.1795C53.0744 37.397 52.8115 36.7886 52.3859 36.2829C51.9602 35.7797 51.417 35.5268 50.7585 35.5268C50.0625 35.5268 49.5067 35.7747 49.0911 36.2704C48.6755 36.7661 48.4126 37.377 48.3099 38.108H48.3074Z"
            fill="#1FBFB7"
          />
          <path
            d="M62.6383 44.2314L57.1929 33.3906H61.3364L64.3858 40.6612L67.6681 33.3906H71.734L62.4305 53.0116H58.4171L62.6383 44.2314Z"
            fill="#1FBFB7"
          />
          <path
            d="M11.0865 30.0379C15.046 30.0379 18.2683 33.2589 18.2683 37.2197C18.2683 41.1805 15.0473 44.4014 11.0865 44.4014C7.12571 44.4014 3.90475 41.1805 3.90475 37.2197C3.90475 33.2589 7.12571 30.0379 11.0865 30.0379ZM11.0865 27.7158C5.83785 27.7158 1.58398 31.971 1.58398 37.2184C1.58398 42.4657 5.83916 46.7209 11.0865 46.7209C16.3339 46.7209 20.5891 42.4657 20.5891 37.2184C20.5891 31.971 16.3339 27.7158 11.0865 27.7158Z"
            fill="#110F19"
          />
          <path
            d="M12.6706 3.16797H10.2949V25.3406H12.6706V3.16797Z"
            fill="#110F19"
          />
          <path
            d="M2.375 9.50241V12.6699H7.12627V9.50241H2.375Z"
            fill="#110F19"
          />
          <path
            d="M0 3.16733L0 5.54297H7.1269V3.16733L0 3.16733Z"
            fill="#1FBFB7"
          />
        </svg>
      </PhantomKeyLogoWrapper>
      <InnerAppContent>
        <BrowserViewContent>
          <CompleteIconWrapper>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="142"
              height="142"
              viewBox="0 0 142 142"
              fill="none"
            >
              <defs>
                <linearGradient
                  id="gradient1"
                  x1="0%"
                  y1="0%"
                  x2="100%"
                  y2="0%"
                  gradientTransform="rotate(91)"
                >
                  <stop offset="59.98%" style={{ stopColor: '#41c7ff' }} />
                  <stop offset="99.3%" style={{ stopColor: '#8b4cff' }} />
                </linearGradient>
              </defs>
              <path
                d="M142 71C142 110.212 110.212 142 71 142C31.7877 142 0 110.212 0 71C0 31.7877 31.7877 0 71 0C110.212 0 142 31.7877 142 71ZM62.7875 108.594L115.465 55.9165C117.254 54.1278 117.254 51.2274 115.465 49.4386L108.987 42.9607C107.198 41.1717 104.298 41.1717 102.509 42.9607L59.5484 85.9209L39.4912 65.8637C37.7024 64.0749 34.802 64.0749 33.013 65.8637L26.5351 72.3416C24.7464 74.1303 24.7464 77.0307 26.5351 78.8194L56.3093 108.594C58.0983 110.383 60.9984 110.383 62.7875 108.594Z"
                fill="url(#gradient1)"
              />
            </svg>
          </CompleteIconWrapper>

          <CompleteTitle>
            Thank you{' '}
            <span>
              {firstName} {lastName}
            </span>{' '}
            <br /> for creating an account at <span>Apex Bank (AB)</span>
          </CompleteTitle>
        </BrowserViewContent>
      </InnerAppContent>
    </InnerAppLayout>
  )
})
