import React, { useEffect } from 'react'
import { Loading } from '../components/Loading/Loading'
import styled from 'styled-components'
import axios from 'axios'

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`

export const RedirectPage = () => {
  const urlParams = new URLSearchParams(window.location.search)

  const createTransaction = async () => {
    const emailAddress = urlParams.get('email')
    const phoneNumber = '+' + urlParams.get('phone')

    if (emailAddress && phoneNumber) {
      try {
        const { data: createTransactionData } = await axios.post<{
          transactionId: string
          proceedUrl: string
        }>(
          'https://api.phantomkey.io/create-transaction',
          {
            redirectUrl: 'https://another.sample-bank.com/',
            emailAddress,
            phoneNumber,
            applicationName: 'Apex onboarding',
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )

        window.location.href = createTransactionData.proceedUrl
      } catch (error) {
        console.log(error)
      }
    }
  }

  useEffect(() => {
    void createTransaction()
  }, [])

  return (
    <LoadingWrapper>
      <Loading
        bgColor="#aaacaa"
        primary="#eee"
        width="90px"
        padding="0 0"
        thickness={6}
      />
    </LoadingWrapper>
  )
}
