import React, { useContext, useState } from 'react'
import { StoreContext } from '../../components/App'
import {
  BurgerButton,
  Container,
  FloatingMenu,
  LogoutButton,
  MenuLogo,
  UlContainer,
  UserInitials,
  UserName,
  UserWrapper,
} from './MobileNavMenu.styles'

import { LogoutIcon } from '../Icons/LogoutIcon'

interface MobileNavMenuItem {
  name: string // needs to be unique
  label: string
  urls: string[]
  icon?: string
}

interface MobileNavMenuProps {
  items: MobileNavMenuItem[]
  margin?: string
}

export const MobileNavMenu = ({ items }: MobileNavMenuProps) => {
  const store = useContext(StoreContext)
  const { userFullName, userFirstName, userLastName } = store.AppState

  const [isOpen, setIsOpen] = useState(false)
  const [display, setDisplay] = useState('none')

  const toggleMenu = () => {
    if (isOpen) {
      setIsOpen(!isOpen)

      setTimeout(() => {
        setDisplay('none')
      }, 300)
    } else {
      setDisplay('flex')

      setTimeout(() => {
        setIsOpen(!isOpen)
      }, 10)
    }
  }

  const logout = () => {}

  return (
    <>
      <Container open={isOpen} display={display}>
        <UlContainer>
          {/* <UserWrapper>
            <UserInitials>{userFirstName[0] + userLastName[0]}</UserInitials>
            <UserName>{userFullName}</UserName>
          </UserWrapper> */}

          <li>
            <a href="#">Cards</a>
          </li>
          <li>
            <a href="#">Business account</a>
          </li>
          <li>
            <a href="#">Corporate account</a>
          </li>
          <li>
            <a href="#">Insurance</a>
          </li>
          <li>
            <a href="#">About us</a>
          </li>
          <li>
            <a href="#">Contact</a>
          </li>
        </UlContainer>

        <LogoutButton onClick={() => logout()}>
          <LogoutIcon />
          Log out
        </LogoutButton>
      </Container>

      <FloatingMenu>
        <MenuLogo>SampleBank</MenuLogo>
        <BurgerButton onClick={toggleMenu}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
          >
            <rect width="40" height="40" rx="10" fill="#EFF1F5" />
            <path
              d="M25.3137 13.6569C20.8954 13.6569 18.4183 13.6569 14 13.6569"
              stroke="black"
              strokeWidth="3"
              strokeLinecap="round"
            />
            <path
              d="M25.3137 19.9706C20.8954 19.9706 18.4183 19.9706 14 19.9706"
              stroke="black"
              strokeWidth="3"
              strokeLinecap="round"
            />
            <path
              d="M25.3137 26.2843C20.8954 26.2843 18.4183 26.2843 14 26.2843"
              stroke="black"
              strokeWidth="3"
              strokeLinecap="round"
            />
          </svg>
        </BurgerButton>
      </FloatingMenu>
    </>
  )
}
